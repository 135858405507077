<template>
    <div style="height: 100%!important;">
        <Header @ok="headOk"></Header>
		
		<div class="div-img-wrap">
			<div class="div1">
			    <a href="/"><img class="top_img" :src="webObj.bannerImage"/></a>
			</div>
		</div>

        <div class="div-main" v-if="topList.length>0">
            <!--                <div class="news-title">{{item.navName}}</div>-->
            <div class="div-main-item-list">
<!--                <a class="arrow arrow-left" @click="prevTopList" src="../assets/img/arrow_left_light.png"></a>-->
                <div class="news-list" style="flex:1;" v-for="(item,index) of topList" :key="index"
                     @click="navArticle(item.navCode,item.id)">
                    <div class="news-item-1" v-if="topList.length===1">
                        <img class="news-img" :src="item.imageSrc">
                        <div class="news-item-content-1">
                            <div class="news-subTitle">{{item.title}}</div>
                            <div class="news-content" v-text="item.content"></div>
                            <div class="news-time">{{item.createTime}}</div>
                        </div>
                    </div>
                    <div class="news-item" v-else>
                        <img class="news-img" :src="item.imageSrc">
                        <div class="news-subTitle">{{item.title}}</div>
                        <div class="news-content" v-text="item.content"></div>
                        <div class="news-time">{{item.createTime}}</div>
                    </div>

                </div>
<!--                <a class="arrow arrow-right" @click="nextTopList" src="../assets/img/arrow_right_light.png"></a>-->

            </div>
        </div>

        <div class="div-main" style="background-color: #f6f7f8;" v-if="list1.length>0">
            <div class="notice-title">通知公告</div>
            <div class="notice-list" :style="{justifyContent: list1.length===1?'flex-start':'space-around'}">
                <div class="notice-item" >
<!--                    <div class="notice-head">-->
<!--                        <span class="h1">{{item.navName}}</span>-->
<!--                        <span class="h2" @click="moreAction(item.navCode)">更多></span>-->
<!--                    </div>-->
                    <div class="notice-subitem-body" v-for="(item,index) of list1" :key="index"
                         @click="navArticle(item.navCode,item.id)">
                        <div style="width: 400px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                            <span class="notice-cate">[{{item.navName}}]{{item.title}}</span>
                            <!--                            <span class="" style="">{{subItem.createTime}}</span>-->
                        </div>
                        <span style="font-size: 12px;">{{item.createTime}}</span>
                    </div>
                </div>

                <div class="notice-item" v-if="list2.length>0" style="margin-left: 20px;">
                    <!--                    <div class="notice-head">-->
                    <!--                        <span class="h1">{{item.navName}}</span>-->
                    <!--                        <span class="h2" @click="moreAction(item.navCode)">更多></span>-->
                    <!--                    </div>-->
                    <div class="notice-subitem-body" v-for="(item,index) of list2" :key="index"
                         @click="navArticle(item.navCode,item.id)">
                        <div style="width: 400px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                            <span class="notice-cate">[{{item.navName}}]{{item.title}}</span>
                            <!--                            <span class="" style="">{{subItem.createTime}}</span>-->
                        </div>
                        <span style="font-size: 12px;">{{item.createTime}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="div-service" v-if="serviceList.length>0">
            <img class="service-bg" src="../assets/img/bg_f5.png"/>
            <div class="service-wrap">
                <div style="display: flex;align-items: center;">
                    <div class="service-title">相关服务</div>
                    <div class="service-itemlist">
						<template v-for="(item,index) of serviceList" >
							<a v-if="item.linkUrl"  :key="index"  :href="item.linkUrl" target="_blank"
							   style="text-decoration: none;color:#0071c1;"
							>
							    <!--                            <img style="width:43px;height: 43px;" :src="item.url">-->
							    <div style="display: flex;flex-direction: column;align-items: center;width:120px">
							        <i :class="'fa '+item.icon" style="font-size:40px;"></i>
							        <span style="font-size: 14px;margin-top: 8px;">{{item.moduleName}}</span>
							    </div>
							</a>
							<div v-else @click="serviceMouseOver(item)" :key="index" 
							   style="text-decoration: none;color:#0071c1;"
							>
							    <!--                            <img style="width:43px;height: 43px;" :src="item.url">-->
							    <div style="display: flex;flex-direction: column;align-items: center;width:120px">
							        <i :class="'fa '+item.icon" style="font-size:40px;"></i>
							        <span style="font-size: 14px;margin-top: 8px;">{{item.moduleName}}</span>
							    </div>
							</div>
						</template>
                       
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
		
		<div class="mask" v-if="showImg">
			<div class="service-img">
				<img style="width: 200px;height: 200px;object-fit: fill;" :src="imgUrl"/>
				<div class="desc" >
					<span>微信扫一扫，快速完成报名</span>
					<span class="close" @click="closeImg">x</span>
				</div>
			</div>
		</div>
		
    </div>
</template>

<script>
    import Header from "./Header";
    import Footer from "./Footer";

    export default {
        name: 'Index',
        components: {Header, Footer},
        data: function () {
            return {

                serviceList: [
                    // {name: '网上报名', url: '../assets/img/service1.png'},
                    // {name: '报考成绩查询', url: '../assets/img/service2.png'},
                    // {name: '录取查询', url: '../assets/img/service3.png'},
                    // {name: '学生选课', url: '../assets/img/service4.png'},
                    // {name: '统考成绩查询', url: '../assets/img/service5.png'},
                    // {name: '外语查询', url: '../assets/img/service6.png'},
                    // {name: '学籍查询', url: '../assets/img/service7.png'},
                    // {name: '学籍异动', url: '../assets/img/service8.png'},
                ],
                webObj: {},
                topList: [],
                list1: [],
                list2: [],
                current:1,
				showImg:false,
				imgUrl:''
            }
        },
        created() {
            this.getList()
            this.getServiceList();
        },
        methods: {
			closeImg(){
				this.showImg=false
				this.imgUrl=''
			},
			serviceMouseOver(item){
				console.log(item)
				this.showImg=true
				this.imgUrl=item.qrcodeUrl	
			},
            headOk(e) {
                this.webObj = e
            },
            navArticle(navCode, articleId) {
                console.log(navCode)
                this.$router.push({path: '/article?id=' + navCode + "&articleId=" + articleId})
            },
            // moreAction(navCode) {
            //     this.$router.push({path: '/article?id=' + navCode})
            // },
            getServiceList() {
                this.$axios.get("/front/company/webServiceList").then(res => {
                    // console.log(res.data)
                    if (res.data.success) {
                        this.$nextTick(() => {
                            this.serviceList = res.data.result
                        })
                    }

                });
            },
            getList() {
                this.$axios.get("/front/company/webModuleIndex").then(res => {
                    if (res.data.success) {
                        let result = res.data.result
                        this.topList = result.topList
                        this.list1 = result.list1
                        this.list2 = result.list2
                        this.current=1
                        // this.picAndDescList = result.picAndDesc
                        // this.list = result.list
                        // console.log(this.list)
                    }
                });
            },
            prevTopList(){
                let current=this.current-1
                this.$axios.get("/front/company/getTopList?current="+current).then(res => {
                    console.log(res.data)
                    if (res.data.success) {
                        let result = res.data.result
                        let topList = result.topList
                        if (topList.length > 0) {
                            this.topList = topList;
                            if(current<=1){
                                current=1
                            }
                            this.current=current
                        }

                    }
                });
            },
            nextTopList(){
                let current=this.current+1
                this.$axios.get("/front/company/getTopList?current="+current).then(res => {
                    if (res.data.success) {
                        let result = res.data.result
                        let topList = result.topList
                        if (topList.length > 0) {
                            this.topList = topList;
                            this.current=current
                        }

                    }
                });
            }
        }

    }
</script>

<style lang="scss">
    html, body {
        padding: 0;
        margin: 0;
    }


    .wrap-top {
        display: flex;
        margin: 0 90px;
        align-items: center;
    }

    .wrap-top .menu {
        font-family: Microsoft YaHei, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        border-bottom: none !important;
    }

    .wrap-top .menu span {
        font-size: 18px !important;
        color: #000000;
    }

    .wrap-top .logo {
        width: 353px;
        height: 49px;
        object-fit: contain;
    }

	.div-img-wrap{
		width: 100%;
		background: #dae8e7;
	}
    .div1 {
        // height: 360px;
        // position: relative;
        // overflow: hidden;
		width: 1300px;
		height: 360px;
		position: relative;
		/* overflow: hidden; */
		margin: 0 auto;
    }

    /* 图片 */
    .top_img {
		object-fit: cover;
        width: 100%;
        height: 100%;
        // position: absolute;
        // left: 50%;
        // top: 0;
        // margin-left: -960px
    }


    /* 主内容 */
    .div-main {
        background: #e8edf1;
        padding: 20px 90px;
        display: flex;
        flex-direction: column;

    }

    .div-main .news-title, .notice-title {
        height: 30px;
        line-height: 30px;
        font-size: 30px;
        font-weight: bold;
        color: #0071c1;
        position: relative;
        /*margin-left: 11px;*/
        /*margin-bottom: 20px;*/
        width: 1200px;
        text-align: left;
        margin: auto;

    }

    /* 学院新闻 */
    .div-main .div-main-item-list .arrow {
        width: 80px;
        height: 80px;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .div-main .div-main-item-list .arrow:hover {
        box-shadow: 1px 1px 3px #000000;
    }

    .div-main .div-main-item-list .arrow-left {
        background-image: url(../assets/img/arrow_left_light.png);

    }

    .div-main .div-main-item-list .arrow-right {
        background-image: url(../assets/img/arrow_right_light.png);

    }

    .div-main .div-main-item-list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: auto;
        width: 1200px;
        .news-list {
            display: flex;
            justify-content: center;

            .news-item-1{
                display: flex;
                padding:0px 20px;
                cursor: pointer;
                img{
                    width: 300px;
                    height: 300px;
                    object-fit:cover;
                    margin-right: 10px;
                    border-radius: 10px;
                }
                .news-item-content-1{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    text-align: left;
                    height: 300px;
                    .news-subTitle {
                        color: #333333;
                        margin-bottom: 20px;
                        font-size: 16px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-weight: 700;
                    }
                    .news-content{
                        flex:1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #333333;
                        font-size: 14px;
                    }
                    .news-time{
                        font-size: 14px;
                        color: #0071c1;
                    }
                }

            }
        }
    }


    .div-main .news-item {
        width: 288px;
        margin: 0 5px;
        text-align: left;
        cursor: pointer;

    }

    .div-main .news-item .news-subTitle {
        color: #333333;
        margin-bottom: 20px;
        font-size: 16px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 700;
    }

    .div-main .news-item .news-content {
        color: #333333;
        font-size: 14px;
        height: 95px;
        line-height: 23px;
        overflow: hidden;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }

    .div-main .news-item .news-time {
        font-size: 14px;
        color: #0071c1;
    }

    .div-main .news-item .news-img {
        width: 288px;
        height: 214px;
        border-radius: 10px;
    }

    /* 通知公告 */
    .div-main .notice-list .notice-item {
        width: 536px;
        background-color: #FFFFFF;
        padding: 20px;
        box-shadow: 1px 1px 5px #cccccc;
        border-radius: 5px;
        flex:1;
        margin:20px 0px;
    }

    .div-main .notice-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: auto;
        width: 1200px;
    }

    .notice-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .notice-head .h1 {
        font-size: 18px;
        color: #666666;
        font-weight: bold;
    }

    .notice-head .h2 {
        font-size: 14px;
        color: #0071C1;
        cursor: pointer;
    }

    .notice-head .h2:hover {
        color: #06558d;
    }

    .notice-subitem-body {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        color: #696171;
        font-size: 14px;
        cursor: pointer;
        text-align: left;
    }

    .notice-subitem-body .notice-cate {
        margin-right: 4px;


    }

    .notice-subitem-body:hover {
        color: #0071c1;
    }

    /* 相关服务 */
    .div-service {
        background-color: #f6f7f8;
        height: 220px;
        position: relative;
        overflow: hidden;
        margin-top: -6px;
    }

    .div-service .service-bg {
        height: 220px;
        position: relative;
        overflow: hidden;
        margin-top: -6px;
    }

    .div-service .service-wrap .service-itemlist {
        display: flex;
        font-size: 16px;
        align-items: center;
        justify-content: flex-start;
        width: 1000px;
        cursor: pointer;
    }

    .div-service .service-wrap {
        position: absolute;
        left: 90px;
        font-size: 30px;
        top: 88px;
        color: #0071C1;
        font-weight: bold;

    }

    .div-service .service-wrap .service-title::after {
        content: "|";
        margin: 0 10px;
    }

    /* 底部 */
    .div-footer-wrap {
        height: 152px;
        background-color: rgba(0, 43, 74, 0.8);
        margin-top: -5px;
        display: flex;
        align-items: center;
        padding: 0 70px;
    }

    .div-footer {
        display: flex;
        height: 120px;
        align-items: center;
    }

    .div-qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .div-footer-desc {
        color: #D9EFFF;
        font-size: 12px;
        margin-left: 100px;
        line-height: 26px;
    }

    .div-footer .footer-weixin-title {
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
    }

    .div-footer .footer-weixin {
        height: 80px;
        width: 80px;
        background-color: rgba(211, 227, 238, 1);
    }
.mask{
	position: fixed;
	left:0;
	right:0;
	top:0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.3);
	.service-img{
		position: absolute;
		left: 0;
		right: 0;
		transform: translateY(50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		
		.desc{
			color:#fff;
			display: flex;
			flex-direction: column;
			align-items: center;
			.close{
				font-size: 48px;
				font-weight: bold;
				cursor: pointer;
				color:#fff;
			}
		}
		
	}
}


</style>
